import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import rehypeRaw from 'rehype-raw';
import dompurify from 'isomorphic-dompurify';
import styles from './MarkdownText.module.css';
import { addQueryParamsToUrl } from 'utils/article-link';
import LinkContext, { useLinkContext } from 'utils/link-context';

const propTypes = {
  text: PropTypes.string,
  queryParams: PropTypes.object,
};

const defaultProps = {
  text: '',
  queryParams: {},
};

// Built to convert markdown input from Directus into react components
//
// Passing a string to this and having trouble getting the entire markdown
// chunk to render? Try passing it as <MarkdownText text={'a string'} />
function MarkdownText({ text, queryParams, className = null }) {
  const klass = clsx(styles.container, className);

  // queryParams && console.log('MarkdownText', queryParams);
  const Link = props => {
    const { href, title, children } = props;
    const updatedHref = addQueryParamsToUrl(href, queryParams);
    const { isLinkInButtonDiv } = useLinkContext();
    return (
      <a
        href={updatedHref}
        title={title}
        target="_blank"
        rel="noopener noreferrer"
        className={isLinkInButtonDiv ? '__content_module_btn' : ''}
      >
        {children}
      </a>
    );
  };

  const Div = props => {
    const { className: divClassName, children } = props;
    const isButtonDiv = divClassName && divClassName.includes('__content_module_btn_wrapper');
    return (
      <LinkContext.Provider value={{ isLinkInButtonDiv: isButtonDiv }}>
        <div className={divClassName}>{children}</div>
      </LinkContext.Provider>
    );
  };

  const components = useMemo(
    () => ({
      a: Link,
      div: Div,
    }),
    [queryParams]
  );

  const sanitized = useMemo(() => {
    return process.env.NEXT_PUBLIC_DISABLE_MARKDOWN_RENDERING === 'true'
      ? text
      : dompurify.sanitize(text);
  }, [text]);

  if (process.env.NEXT_PUBLIC_DISABLE_MARKDOWN_RENDERING === 'true') {
    return (
      <div className={klass}>
        <p>{text}</p>
      </div>
    );
  }

  return (
    <div className={klass}>
      <ReactMarkdown components={components} rehypePlugins={[rehypeRaw]}>
        {sanitized}
      </ReactMarkdown>
    </div>
  );
}

MarkdownText.propTypes = propTypes;
MarkdownText.defaultProps = defaultProps;

export default MarkdownText;
