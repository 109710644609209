import { createContext, useContext } from 'react';

const LinkContext = createContext({
  isLinkInButtonDiv: false,
});

export function useLinkContext() {
  return useContext(LinkContext);
}

export default LinkContext;
